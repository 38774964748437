import React, { useCallback, useState } from 'react';

import { Select } from 'antd';

type OptionType = {
  label: string;
  value: string;
};

type Props = {
  options: OptionType[];
  onChange: (value: string[]) => void;
  text: string;
  onClick: (question: string, value: string) => void;
};

export const MultiSelectDropDown = ({
  onChange,
  text,
  options,
  onClick,
}: Props) => {
  const [selected, setSelected] = useState(false);
  const handleOnClick = useCallback(() => {
    onClick(text, text);
    setSelected(pv => {
      return !pv;
    });
  }, [setSelected, onClick, text]);

  return (
    <div className="flex flex-row h-9 ml-2 ">
      <div
        style={{
          border: '1px solid black',
          lineHeight: '33px',
        }}
        className={`
        cursor-pointer
        ${selected ? 'text-white bg-primary-600' : 'text-black'}
        ${selected ? 'border-blue' : 'border-black'}
        px-2
        rounded-md
        text-center
        mr-2

      `}
        onClick={handleOnClick}
      >
        {text}
      </div>
      {selected && (
        <div className="align-middle justify-center self-center w-56 pr-2">
          <Select
            mode="tags"
            maxTagCount={'responsive'}
            dropdownMatchSelectWidth={false}
            placeholder="Pick"
            onChange={onChange}
            options={options}
            size={'middle'}
            style={{}}
          />
        </div>
      )}
    </div>
  );
};
