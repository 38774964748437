import {
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Radio,
  Select,
  Space,
  InputNumber,
  Steps,
  Button,
  Result,
  Checkbox,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { NotAvailableStateModal } from '../Payment/components/NotAvailableStateModal';
import {
  useEmployeePublicDetails,
  useEmployeeRegistration,
} from '../../hooks/Api/employee';

import { useRegisterProspectEmployee } from '../../hooks/Api/prospect';
import {
  FORM_FIELD_VALUES_ENUM,
  SignupFormStep1,
} from '../NursePartnerSignup/types';
import { StateName } from '../Payment/States';
import { DateOfBirth } from '../EmployeeSignup/components/DateOfBirth';
import { AilmentCards } from '../NursePartnerSignup/components/AilmentCards';
import { PACKAGE_UNIQUE_CODE } from '../../services/package.static';
import { FertilityQuestionnaireForm } from '../NursePartnerSignup/components/Questionnaires/FertilityQuestionnaireForm';
import { FERTILITY_QUESTIONNAIRE } from '../NursePartnerSignup/components/NuresQuestionnair';
import { FormLabel } from '../NursePartnerSignup/NursePartnerSignup.FormStep1';

const { Header, Footer, Content } = Layout;

type Step = {
  key: number;
  title: string;
  bang: string;
};

const STEPS = [
  {
    key: 0,
    title: 'Getting Started',
    // description: 'Start setting-up your account',
    bang: 'get-started',
  },
  {
    key: 1,
    title: 'Basic Information',
    // description: 'Let us know you better',
    bang: 'basic-information',
  },
  {
    key: 2,
    title: 'Completed',
    // description: 'You are ready to get our services',
    bang: 'completed',
  },
];

type ParamsProps = {
  employerCode: string;
};

export const PatientPartnerSignup = () => {
  const { employerCode = '' } = useParams<ParamsProps>();
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    data: employerData,
    isLoading: employerDataIsLoading,
    error,
  } = useEmployeePublicDetails(employerCode);

  /* Register prospect */
  const { registerProspectEmployee } = useRegisterProspectEmployee();

  useEffect(() => {
    if (error) {
      navigate('/employer-not-found');
    }
  }, [error]);

  useEffect(() => {
    if (employerData) {
      setTimeout(() => {
        window.parent.postMessage(document.body.scrollHeight, '*');
      }, 100); // Keeping this timeout as we would like to wait for the form to render
    }
  }, [employerData]);

  useEffect(() => {
    function sendHeight() {
      const height = document.body.scrollHeight;
      window.parent.postMessage(height, '*');
    }

    window.addEventListener('load', sendHeight);
    window.addEventListener('resize', sendHeight);
  }, []);

  const [createUserPayload, setCreateUserPayload] = useState({});
  const [formStep2] = Form.useForm();

  /* Local states */
  const [currentStep, setCurrentStep] = useState<Step>(STEPS[0]);
  const [selectedState, selectState] = useState<string>('');

  const [notAvailableModal, setNotAvailableModal] = useState<boolean>(false);

  /* Out of service zone */
  const [outOfService, setOutOfService] = useState(false);

  /* Apis */
  const { registerEmployee, isLoading, isSuccess } = useEmployeeRegistration();

  const [formStep1] = Form.useForm<SignupFormStep1>();
  const formWatchBehalf = Form.useWatch<string>(
    FORM_FIELD_VALUES_ENUM.IS_DEPENDANT,
    formStep1,
  );
  const selectedPackages = Form.useWatch<string>(
    FORM_FIELD_VALUES_ENUM.PACKAGE_NAME,
    formStep1,
  );

  const renderHeader = useCallback(() => {
    return (
      <div className="rounded-2xl justify-items-center items-center flex flex-col">
        {employerData?.jointLogo ? (
          <Image
            className="self-center justify-self-center max-w-sm"
            src={employerData.jointLogo}
            preview={false}
          />
        ) : null}
      </div>
    );
  }, [employerData, employerDataIsLoading]);
  const handleNextStep = useCallback(() => {
    /* Navigate to success page */
    setFormSubmitted(true);
  }, [setCurrentStep]);

  const handleSubmitForm = useCallback(() => {}, [
    createUserPayload,
    formStep2,
    employerCode,
  ]);

  useEffect(() => {
    if (isSuccess) {
      handleNextStep();
    }
  }, [handleNextStep, isSuccess]);

  const renderContent = useCallback(() => {
    if (formSubmitted) {
      return (
        <div className="items-center justify-center flex flex-1 flex-col">
          <Result status="success" title="Registration Completed " />
        </div>
      );
    }
    if (employerDataIsLoading) {
      return null;
    }
    return (
      <div className="rounded-2xl bg-white w-full p-6 ">
        <Space className="w-full" size={20} direction="vertical">
          <div className="text-center text-2xl">
            {'Bastion Health digital men’s health clinic'}
          </div>
          <div className="">
            <Form
              wrapperCol={{ span: 12 }}
              size="large"
              name="step1"
              layout="vertical"
              key="step1-form"
              form={formStep1}
              onFinish={async v => {
                await formStep1.validateFields();
                setFormSubmitted(true);
              }}
            >
              <Form.Item
                name={FORM_FIELD_VALUES_ENUM.IS_DEPENDANT}
                label={<FormLabel>I am applying for</FormLabel>}
                valuePropName="value"
                rules={[
                  {
                    required: true,
                    message: 'Please tell us whom you are applying for.',
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={false}>Myself</Radio>
                    <Radio value>A partner or dependent</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Divider />
              <Form.Item
                rules={[{ type: 'email' }]}
                name={FORM_FIELD_VALUES_ENUM.EMAIL}
                required
                label="Email Address"
              >
                <Input
                  css={css`
                    border: 1px solid rgb(212, 212, 212);
                  `}
                />
              </Form.Item>

              <Form.Item
                name={FORM_FIELD_VALUES_ENUM.FIRSTNAME}
                rules={[
                  {
                    required: true,
                    message: 'Please fill in the firstname value.',
                  },
                ]}
                label="Firstname"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={FORM_FIELD_VALUES_ENUM.MIDDLE_NAME}
                required={false}
                label="Middle name"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={FORM_FIELD_VALUES_ENUM.LASTNAME}
                rules={[
                  {
                    required: true,
                    message: 'Please fill in the lastname value.',
                  },
                ]}
                label="Lastname"
              >
                <Input />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select your state',
                  },
                ]}
                label="State"
                name={FORM_FIELD_VALUES_ENUM.STATE}
                required
              >
                <Select
                  showSearch
                  placeholder="Select state"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.values(StateName).map(item => (
                    <Select.Option value={item.abbr} key={item.value}>
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                required
                rules={[
                  {
                    required: true,
                    message: 'Your date of birth is not valid',
                  },
                ]}
                name={FORM_FIELD_VALUES_ENUM.DOB}
                label="Date of birth"
              >
                <DateOfBirth form={formStep1} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message:
                      'Please add phone number. This is required for Bastion Care-Team to reach out to you.',
                  },
                  {
                    validator: async (rule, value, callback) => {
                      if (!value || `${value}`.length !== 10) {
                        throw new Error('Phone number is incorrect!');
                      }
                    },
                  },
                ]}
                required
                name={FORM_FIELD_VALUES_ENUM.PHONE}
                label="Phone"
              >
                <InputNumber
                  controls={false}
                  minLength={9}
                  placeholder="8602451640"
                  addonBefore="+1"
                  className="w-full "
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message:
                      'Please select at least one of the packages above.',
                  },
                ]}
                required
                name={FORM_FIELD_VALUES_ENUM.PACKAGE_NAME}
                label="Package"
                wrapperCol={{ span: 24 }}
              >
                <AilmentCards
                  onSelect={(value: string[]) => {
                    formStep1.setFieldsValue({
                      [FORM_FIELD_VALUES_ENUM.PACKAGE_NAME]: value,
                    });
                  }}
                />
              </Form.Item>

              <Button
                htmlType="submit"
                icon={<ArrowRightOutlined />}
                type="primary"
              >
                Create Account
              </Button>
            </Form>
          </div>
        </Space>
      </div>
    );
  }, [currentStep, employerDataIsLoading, formSubmitted]);

  return (
    <div className="h-screen">
      <Layout className="px-12 pb-12">
        {renderHeader()}

        <Content>{renderContent()}</Content>
      </Layout>
      <NotAvailableStateModal
        selectedState={selectedState}
        visible={notAvailableModal}
        ailment=""
        onClose={() => {
          selectState('');
          setNotAvailableModal(false);
        }}
      />
    </div>
  );
};
