import React, { useEffect, useState, useCallback } from 'react';
import { startCase } from 'lodash';

type Props = {
  text: string;
  onClick: (question: string, value: string) => void;
};

export const RadioButtonSelect = ({ text, onClick }: Props) => {
  //  A component that renders a button that can be selected or unselected.
  // Keeps a local state of the button
  // Changes the boarder and background color of the button when selected
  // Changes the boarder and background color of the button when unselected
  // The button text is passed in as a prop
  // The button text is rendered in the button
  const [selected, setSelected] = useState(false);

  const handleOnClick = useCallback(() => {
    onClick(text, text);
    setSelected(pv => {
      return !pv;
    });
  }, [setSelected, onClick, text]);

  return (
    <div
      style={{
        border: '1px solid black',
      }}
      className={`

        inline
        p-2
        cursor-pointer

        ${selected ? 'text-white bg-primary-600' : 'text-black'}
        ${selected ? 'border-blue' : 'border-black'}

        rounded-md
        m-2
      `}
      onClick={handleOnClick}
    >
      {startCase(text)}
    </div>
  );
};
