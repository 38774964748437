export const PACKAGE_UNIQUE_CODE = {
  maleInfertility: 'maleInfertility',
  prostateEnlargement: 'prostateEnlargement',
  prostateCancerScreening: 'prostateCancerScreening',
  uti: 'uti',
  bladderCancerScreening: 'bladderCancerScreening',
  hormonalHealth: 'hormonalHealth',
  sexualHealth: 'sexualHealth',
} as const;

export type PackageUniqueCode =
  typeof PACKAGE_UNIQUE_CODE[keyof typeof PACKAGE_UNIQUE_CODE];

export const AILMENTS = [
  {
    image: 'https://ik.imagekit.io/bastion/fertility-health-preparatory.jpg',
    name: 'Male Infertility',
    subName: '50% of infertility cases are due to male factors',
    description:
      'In-depth evaluation for male infertility factors. This includes specialist virtual visits, advanced diagnostic testing from home, and a tailored approach to treatment and fertility enhancement.',
    value: PACKAGE_UNIQUE_CODE.maleInfertility,
    relatedPackages: [
      PACKAGE_UNIQUE_CODE.sexualHealth,
      PACKAGE_UNIQUE_CODE.hormonalHealth,
    ],
    icon: 'https://ik.imagekit.io/bastion/icon/fertility-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/fertility-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Prostate Enlargement',
    subName: '1 in 2 males after age 45',
    description:
      'Comprehensive at-home assessment for prostate enlargement. This package includes virtual consultations with specialists, complete at-home testing kits, and personalized treatment plans if needed.',
    value: PACKAGE_UNIQUE_CODE.prostateEnlargement,
    relatedPackages: [
      PACKAGE_UNIQUE_CODE.bladderCancerScreening,
      PACKAGE_UNIQUE_CODE.prostateCancerScreening,
      PACKAGE_UNIQUE_CODE.uti,
    ],
    icon: 'https://ik.imagekit.io/bastion/icon/prostate-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/prostate-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Prostate Cancer Screening',
    subName: '1 in 8 males will be diagnosed with prostate cancer',
    description:
      'Thorough prostate cancer screening and care. Includes expert virtual consultations, at-home cancer screening tests, and personalized follow-up care plans based on screening outcomes.',
    value: PACKAGE_UNIQUE_CODE.prostateCancerScreening,
    relatedPackages: [
      PACKAGE_UNIQUE_CODE.bladderCancerScreening,
      PACKAGE_UNIQUE_CODE.prostateEnlargement,
      PACKAGE_UNIQUE_CODE.uti,
    ],
    icon: 'https://ik.imagekit.io/bastion/icon/prostate-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/prostate-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Sexual Health',
    subName: '1 in 3 males after age 18',
    description:
      'Complete sexual health assessment and support. Our package offers virtual consultations with healthcare experts, at-home testing, and customized treatment plans to address various sexual health concerns.',
    value: PACKAGE_UNIQUE_CODE.sexualHealth,
    relatedPackages: [PACKAGE_UNIQUE_CODE.hormonalHealth],
    icon: 'https://ik.imagekit.io/bastion/icon/ed.png',
    selectedIcon: 'https://ik.imagekit.io/bastion/icon/ed-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Hormonal Health (Low T)',
    subName: '1 in 4 males after age 30',
    description:
      'Focused evaluation and management of hormonal health, including low testosterone. Features include specialist virtual consultations, comprehensive hormonal testing at home, and individualized treatment strategies.',
    value: PACKAGE_UNIQUE_CODE.hormonalHealth,
    relatedPackages: [PACKAGE_UNIQUE_CODE.sexualHealth],
    icon: 'https://ik.imagekit.io/bastion/icon/sexual-health.png',
    selectedIcon: 'https://ik.imagekit.io/bastion/icon/sexual-health-white.png',
  },
  {
    image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
    name: 'Urinary Tract Infection (UTI)',
    subName: '1 in 7 males will be diagnosed with UTI',
    description:
      'Detailed assessment and treatment for UTIs. This package provides virtual specialist consultations, at-home testing for UTI diagnosis, and prompt, effective treatment plans as needed.',
    value: PACKAGE_UNIQUE_CODE.uti,
    relatedPackages: [],
    icon: 'https://ik.imagekit.io/bastion/icon/general-health.png',
    selectedIcon:
      'https://ik.imagekit.io/bastion/icon/general-health-white.png',
  },
  // {
  //   image: 'https://ik.imagekit.io/bastion/prostate-health-preparatory.jpg',
  //   name: 'Bladder Cancer Screening',
  //   description:
  //     'Comprehensive at-home prostate testing and assessment. Includes at-home test kits, specialist virtual visits, and if needed treatment plan.',
  //   value: PACKAGE_UNIQUE_CODE.bladderCancerScreening,
  //   relatedPackages: [],
  //   icon: 'https://ik.imagekit.io/bastion/icon/general-health.png',
  //   selectedIcon:
  //     'https://ik.imagekit.io/bastion/icon/general-health-white.png',
  // },
];
