import {
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import React, { useCallback, useState } from 'react';
import { isEmpty, unionBy } from 'lodash';
import {
  QUESTIONNAIRE_FORMS,
  FERTILITY_QUESTIONNAIRE,
  OPTION_INPUT_TYPES,
} from '../NuresQuestionnair';
import { RadioButtonSelect } from '../RadioButtonSelect';
import { MultiSelectDropDown } from '../MultiSelectDropDown';
import { FreeTextInput } from '../FreeTextInput';
import { FORM_FIELD_VALUES_ENUM } from '../../types';

interface SubQuestion {
  type: OPTION_INPUT_TYPES;
  question: string;
  options?: string[];
  optional: boolean;
  subQuestions?: SubQuestion[];
}

interface Question {
  type: string;
  question: string;
  subQuestions?: SubQuestion[];
}

type Props = {
  questions: Question[];
  sectionTitle: string;
};

export const FertilityQuestionnaireForm = ({
  questions,
  sectionTitle,
}: Props) => {
  const [form] = Form.useForm();
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: string]: any;
  }>({});

  const handeOnItemSelect = useCallback(
    (question: string, value: any) => {
      setSelectedAnswers(pv => {
        if (pv[question]) {
          const { [question]: _, ...rest } = pv;
          return rest;
        }
        return { ...pv, [question]: value };
      });
    },
    [setSelectedAnswers],
  );

  const renderFormItem = useCallback(
    (question: SubQuestion) => {
      switch (question.type) {
        case OPTION_INPUT_TYPES.SINGLE_SELECT:
          return (
            <RadioButtonSelect
              key={question.label}
              onClick={handeOnItemSelect}
              text={question.label}
            />
          );
        case OPTION_INPUT_TYPES.NUMBER:
          return <InputNumber placeholder="0" />;
        case OPTION_INPUT_TYPES.FREE_TEXT:
          return (
            <FreeTextInput
              text={question.label}
              onChange={(value: string) => {
                handeOnItemSelect(question.label, value);
              }}
              onClick={handeOnItemSelect}
            />
          );
        case OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN:
          return (
            <MultiSelectDropDown
              text={question.label}
              onChange={(value: string[]) => {
                handeOnItemSelect(question.label, value);
              }}
              onClick={handeOnItemSelect}
              options={
                question?.options?.map(item => ({
                  label: item,
                  value: item,
                })) || []
              }
            />
          );
        default:
          return null;
      }
    },
    [handeOnItemSelect],
  );

  const renderSubQuestions = useCallback(
    (question: SubQuestion) => {
      const isSelected = selectedAnswers[question.label];
      return (
        <div className="  w-full py-2">
          <RadioButtonSelect
            onClick={handeOnItemSelect}
            text={question.label}
          />
          {isSelected && (
            <div className="pl-3 grid grid-cols-1 bg-gray-200 shadow-lg m-5 rounded-xl pb-5">
              {question?.subQuestions?.map((subQuestion, index) => (
                <>
                  <div className="mt-1 flex items-center">
                    <div className="p-3 ">{subQuestion.label}</div>
                    <div className="align-middle">
                      <Form.Item name={subQuestion.label} className=" h-3">
                        {renderFormItem(subQuestion)}
                      </Form.Item>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </div>
      );
    },
    [selectedAnswers, renderFormItem],
  );

  if (isEmpty(questions)) return null;

  return (
    <Collapse defaultActiveKey={['1']} className="mb-6">
      <Collapse.Panel header={sectionTitle} key="1">
        {questions.map((item, index) => (
          <>
            <div />
            <Form.Item
              wrapperCol={{ span: 24 }}
              name={item.question}
              label={item.question}
            >
              <div className="bg-gray-50 rounded-xl py-5 pl-5">
                <div className="flex-wrap flex-row flex gap-0 ">
                  {item.subQuestions.map((subQuestion, subIndex) => {
                    return (
                      <>
                        <Form.Item
                          style={{
                            height: 18,
                          }}
                          name={subQuestion.label}
                        >
                          {renderFormItem(subQuestion)}
                        </Form.Item>
                        {subQuestion.subQuestions &&
                          renderSubQuestions(subQuestion)}
                      </>
                    );
                  })}
                </div>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  name={`${item.question}-${FORM_FIELD_VALUES_ENUM.NURSE_NOTES}`}
                  label={`${item.question} Notes`}
                >
                  <Input.TextArea
                    placeholder={`Extra notes related to ${item.question}`}
                    className="w-1/2"
                  />
                </Form.Item>
              </div>
            </Form.Item>
          </>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};
