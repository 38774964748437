import axios, { AxiosInstance, AxiosPromise } from 'axios';
import type { GetClientSecret, ProductInfoParams } from './types';
import { RegisterEmployeeParams, RegisterProspectParams } from './types';

export type Api = {
  userCurrent: () => AxiosPromise;
};

const makeRequest = async (
  handleResponse: any,
  api: AxiosInstance,
  method: 'get' | 'post' | 'put' | 'delete' | 'patch',
  url: string,
  body?: any,
  params?: any,
) => {
  let response;
  try {
    switch (method) {
      case 'get':
        response = await api.get(url, params ? { params } : undefined);
        break;
      case 'post':
        response = await api.post(url, body, params ? { params } : undefined);
        break;
      case 'put':
        response = await api.put(url, body, params ? { params } : undefined);
        break;
      case 'delete':
        response = await api.delete(url, params ? { params } : undefined);
        break;
      case 'patch':
        response = await api.patch(url, body, params ? { params } : undefined);
        break;
      default:
        response = await api.get(url, params ? { params } : undefined);
        break;
    }
    handleResponse(response);
    return {
      ...response,
      ok: response.status >= 200 && response.status <= 299,
    };
  } catch (e) {
    if (e.response) {
      handleResponse(e.response);
      throw e.response;
    }
    throw e;
  }
};

const makeApi = () => {
  let handleResponse = () => {};
  // Temp decision while athena has sample data
  const apiInstance = axios.create({
    timeout: 50000, // 50 seconds
    baseURL: process.env.API_URL,
  });

  const setResponseHandler = (callback: any) => {
    handleResponse = callback;
  };

  const setToken = (token: string) => {
    apiInstance.defaults.headers.common['X-API-Key'] = token;
  };

  const getStripeClientSecret = (params: GetClientSecret) =>
    makeRequest(
      handleResponse,
      apiInstance,
      'post',
      `/package/stripe-session-id`,
      params,
    );

  const getProductDetails = ({ productId, coupon }: ProductInfoParams) =>
    makeRequest(
      handleResponse,
      apiInstance,
      'get',
      `/package/${productId}${(coupon && `?coupon=${coupon}`) || ''}`,
    );

  const registerProspect = (body: RegisterProspectParams) =>
    makeRequest(handleResponse, apiInstance, 'post', `/prospect/`, body);
  const registerProspectEmployee = (body: RegisterProspectParams) =>
    makeRequest(
      handleResponse,
      apiInstance,
      'post',
      `/prospect/employee-signup/`,
      body,
    );
  const registerEmployee = (body: RegisterEmployeeParams) =>
    makeRequest(
      handleResponse,
      apiInstance,
      'post',
      `/user/employee-signup`,
      body,
    );
  const getEmployerPublicDetails = (employerCode: string) =>
    makeRequest(
      handleResponse,
      apiInstance,
      'get',
      `/organization/employer/${employerCode}`,
    );
  const getAllDepartments = () =>
    makeRequest(handleResponse, apiInstance, 'get', `/departments`);

  const getAllProducts = () =>
    makeRequest(handleResponse, apiInstance, 'get', `/package/stripe/all`);

  return {
    setToken,
    setResponseHandler,
    getStripeClientSecret,
    getProductDetails,
    getAllProducts,
    registerProspect,
    registerEmployee,
    getEmployerPublicDetails,
    getAllDepartments,
    registerProspectEmployee,
  };
};

const api = makeApi();

export default api;
