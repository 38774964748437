import { version } from '../package.json';

export const dateFormat = 'MM/DD/YYYY';
export const timeFormat = 'HH:mm';
export const timeFormatWithSeconds = `${timeFormat}:ss`;
export const maxAttachmentSize = 15000; // in KB
export const UpcomingAppointmentsRefreshRate =
  parseInt(process.env.UPCOMING_APPOINTMENTS_REFRESH_RATE || '', 10) || 10; // in seconds

export const API =
  process.env.API_URL || 'http://localhost:8000/platform-core/v1/';
export const VERSION = version;

export const athenaConfig = {
  baseURL: process.env.ATHENAHEALTH_BASE_URL,
  practiceId: process.env.ATHENAHEALTH_PRACTICE_ID,
};

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_WEB_APP_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

export const images = {
  bastionGoldenLogo: 'https://ik.imagekit.io/bastion/bastion-logo-gold.png',
};
