import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { upperFirst } from 'lodash';
import { useNavigate } from 'react-router-dom';

export enum AilmentType {
  PROSTATE = 'prostate',
  REPRODUCTIVE = 'reproductive',
}
type Props = {
  visible: boolean;
  onClose: () => void;
  selectedState: string;
  ailment: string;
};

export const NotAvailableStateModal = (props: Props) => {
  const { visible, onClose, selectedState, ailment } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({
    visible: false,
  });

  useEffect(() => {
    setState({ visible });
  }, [visible, setState]);

  const showModal = useCallback(() => {
    setState({
      ...state,
      visible: true,
    });
  }, [state, setState]);
  const handleOk = useCallback(() => {
    setState({ ...state });
    setTimeout(() => {
      setState({ visible: false });
    }, 3000);
  }, [state, setState]);

  const handleCancel = useCallback(() => {
    setState({ visible: false });
    onClose();
  }, [setState, onClose]);

  return (
    <Modal
      visible={state.visible}
      title="Uh oh!"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          className="w-full"
          key="back"
          onClick={() => {
            if (ailment === AilmentType.PROSTATE) {
              navigate('/prostate-health-preparatory');
            } else if (ailment === AilmentType.REPRODUCTIVE) {
              navigate(`/men's-reproductive-health-preparatory`);
            } else {
              navigate('/all');
            }
            handleCancel();
          }}
        >
          {`Buy a ${upperFirst(ailment)} Health preparatory plan`}
        </Button>,
      ]}
    >
      <div>
        <p className="font-bold text-3xl center text-center">{`Bastion is not currently available in ${selectedState}...`}</p>
      </div>
    </Modal>
  );
};
