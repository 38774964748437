import React, { useState } from 'react';
import { FormInstance, Input, Form, Button } from 'antd';
import { isEmpty } from 'lodash';
import { PersonalInformationType } from 'utils/types';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import styled from '@emotion/styled';

export const ConsentLink = styled.a`
  color: #1890ff;
  text-decoration: underline;
`;

type Props = {
  form: FormInstance;
  onNext: (params: PersonalInformationType) => void;
};
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};
export const PersonalInformation = (props: Props) => {
  const { form, onNext } = props;
  const [state, setState] = useState<
    PersonalInformationType & { privacyPolicyIsChecked: boolean }
  >({
    email: '',
    firstName: '',
    lastName: '',
    privacyPolicyIsChecked: false,
  });
  return (
    <Form
      onValuesChange={value => {
        setState({ ...state, ...value });
      }}
      {...layout}
      form={form}
    >
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ type: 'email' }, { required: true }]}
        name="email"
        label="Email"
      >
        <Input placeholder="Email" autoComplete="email" />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true }]}
        name="firstName"
        label="First name"
      >
        <Input placeholder="James" />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true }]}
        name="lastName"
        label="Last name"
      >
        <Input placeholder="Winston" />
      </Form.Item>
      <Checkbox
        onChange={(e: CheckboxChangeEvent) => {
          setState({ ...state, privacyPolicyIsChecked: e.target.checked });
        }}
      >
        I accept{' '}
        <ConsentLink
          target="_blank"
          href="https://getbastion.com/online-terms-of-use"
        >
          Online Terms of use
        </ConsentLink>
        {', '}
        <ConsentLink
          target="_blank"
          href="https://getbastion.com/online-privacy-policy"
        >
          Online Privacy Policy
        </ConsentLink>
        {' & '}
        <ConsentLink
          target="_blank"
          href="https://getbastion.com/notice-of-privacy-practices"
        >
          Notice of Privacy Practices
        </ConsentLink>
      </Checkbox>
      <p className="text-gray-500 text-xs ">
        You will receive receipts and notifications at this email address.
      </p>
      <Button
        onClick={() => {
          onNext(state);
        }}
        disabled={
          isEmpty(state.email) ||
          isEmpty(state.firstName) ||
          isEmpty(state.lastName) ||
          !state.privacyPolicyIsChecked
        }
        className="w-full mt-5"
        type="default"
      >
        Continue
      </Button>
    </Form>
  );
};
