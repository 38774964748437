import React, { useCallback, useEffect, useMemo, useState, VFC } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Steps, Button, Select, Empty, Form } from 'antd';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import colors from 'constants/colors.json';
import { css } from '@emotion/react';
import { CheckCircleOutlined, SecurityScanOutlined } from '@ant-design/icons';
import qs from 'query-string';
import { PersonalInformationType } from 'utils/types';
import { useGetAllDepartments } from 'hooks/Api/department';
import { OrderSummary } from './components/OrderSummary';
import { Header } from './Header';

import { StateName } from './States';
import { Loading } from './components/Loading';
import { NotAvailableStateModal } from './components/NotAvailableStateModal';
import { PersonalInformation } from './personalInformation';
import {
  useGetStripeClientSecret,
  useGetProductDetails,
  useApplyCoupon,
} from '../../hooks/Api/stripe';
import { useRegisterProspect } from '../../hooks/Api/prospect';

export enum ProductType {
  TREATMENT = 'treatment',
  PREPARATORY = 'preparatory',
}

const { Option } = Select;

const { REACT_APP_STRIPE_PK } = process.env;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(REACT_APP_STRIPE_PK || '');

type ParamsProps = {
  productId: string;
};

export const PaymentPage: VFC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [notAvailableModal, setNotAvailableModal] = useState<boolean>(false);
  const [personalInformation, setPersonalInformation] =
    useState<PersonalInformationType>({
      firstName: '',
      lastName: '',
      email: '',
    });
  const [selectedState, selectState] = useState<string>('');
  const { productId = '' } = useParams<ParamsProps>();
  const [searchParams, setSearchParams] = useSearchParams();
  const coupon = searchParams.get('coupon');
  const [personalInformationForm] = Form.useForm();
  const [stateForm] = Form.useForm();

  const {
    data: productData,
    error: productError,
    isLoading: loadingProductDetails,
    isRefetching,
    refetch: refetchProductInfo,
  } = useGetProductDetails({ productId, coupon });

  const { departments } = useGetAllDepartments();
  const activeDepartments = useMemo(() => {
    return departments
      ?.filter(department => department.active)
      .map(department => department.state.toUpperCase());
  }, [departments]);
  const { registerProspect } = useRegisterProspect();

  useEffect(() => {
    refetchProductInfo();
  }, [productId, refetchProductInfo, coupon]);

  const {
    data: stripeSessionId,
    error,
    mutate,
    isLoading: stripeClientIsLoading,
  } = useGetStripeClientSecret();
  useEffect(() => {
    const handleStripe = async (sessionId: string) => {
      const stripe = await stripePromise;

      await stripe?.redirectToCheckout({
        sessionId,
      });
    };
    if (stripeSessionId) {
      handleStripe(stripeSessionId);
    }
  }, [stripeSessionId]);

  const renderEmailCollection = useCallback(() => {
    if (currentStep === 0) {
      return (
        <div className="w-full transition-all ease-in-out duration-300 ">
          <div className="rounded bg-white p-4 flex flex-col justify-between">
            <div className="mb-8">
              <div className="text-gray-900 font-bold text-xl mb-2">
                Your Email
              </div>
              <PersonalInformation
                form={personalInformationForm}
                onNext={(value: PersonalInformationType) => {
                  setPersonalInformation(value);
                  setCurrentStep(currentStep + 1);
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="w-full flex flex-row justify-between bg-gray-300 shadow rounded ">
        <div>
          <div className=" p-4 flex flex-col">
            <div className=" font-bold text-xl mb-2">Your Email</div>
            <div className="text-gray-800 mb-2">
              {personalInformation?.email}
            </div>
          </div>
        </div>
        <div className="self-center pr-3">
          <CheckCircleOutlined style={{ fontSize: 34, color: '#039926' }} />
        </div>
      </div>
    );
  }, [currentStep, personalInformation, personalInformationForm]);

  const renderPaymentForm = useCallback(() => {
    if (currentStep === 2) {
      return (
        <div className="flex flex-1 p-16 center rounded-lg bg-white pt-4 mt-3" />
      );
    }
    return (
      <div className="w-full mt-3">
        <div className="bg-gray-400 rounded p-4 flex flex-col">
          <div className="text-gray-600 font-bold text-xl mb-2">
            Payment and Coupon
          </div>
        </div>
      </div>
    );
  }, [currentStep]);
  const renderStateSelection = useCallback(() => {
    if (currentStep === 1) {
      return (
        <div className="w-full mt-3 shadow rounded pb-10 pt-5">
          <Form className="rounded bg-white p-4 flex flex-col justify-between w-full">
            <div className="text-gray-900 font-bold text-xl mb-5">
              Select your state
            </div>
            <Select
              showSearch
              className="w-full"
              placeholder="Select state"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={async (value: string) => {
                if (
                  !activeDepartments?.includes(value.toUpperCase()) &&
                  productData.metadata.type !== ProductType.PREPARATORY
                ) {
                  setNotAvailableModal(true);
                }
                await registerProspect({
                  ...personalInformation,
                  coupon,
                  packageName: productId,
                  state: value,
                });
                selectState(value);
              }}
            >
              {Object.values(StateName).map(item => {
                return <Option value={item.abbr}>{item.value}</Option>;
              })}
            </Select>
            <Button
              onClick={async () => {
                setCurrentStep(currentStep + 1);
                mutate({
                  ...personalInformation,
                  productId: productData?.id,
                  coupon,
                  state: selectedState,
                });
              }}
              disabled={isEmpty(selectedState)}
              className="w-full mt-5"
              type="default"
            >
              Continue
            </Button>

            <NotAvailableStateModal
              selectedState={selectedState}
              visible={notAvailableModal}
              ailment={productData?.metadata?.ailment}
              onClose={() => {
                selectState('');
                setNotAvailableModal(false);
              }}
            />
          </Form>
        </div>
      );
    }
    return (
      <div className="w-full mt-3">
        <div className="bg-gray-400 rounded p-4 flex flex-col">
          <div className="text-gray-600 font-bold text-xl mb-2">
            Select your state
          </div>
        </div>
      </div>
    );
  }, [
    currentStep,
    selectedState,
    notAvailableModal,
    productData,
    personalInformation,
    coupon,
    productId,
    registerProspect,
    mutate,
    activeDepartments,
  ]);

  const renderSteps = useCallback(() => {
    return (
      <div className="w-full flex flex-1 justify-center flex-col">
        {renderEmailCollection()}
        {renderStateSelection()}
        {renderPaymentForm()}
      </div>
    );
  }, [
    renderEmailCollection,
    renderPaymentForm,
    // currentStep,
    renderStateSelection,
  ]);

  if (loadingProductDetails && !productError) {
    return (
      <div className="flex flex-1 md:flex-row min-h-screen flex-col ">
        <div
          css={css`
            background-color: ${colors.primary[500]};
          `}
          className="flex flex-1 flex-col justify-around "
        />
        <div className="flex-1 " />
      </div>
    );
  }

  if (productError) {
    return (
      <div>
        <Header />
        <div className="flex justify-center mt-6 xl-max-w-screen-2xl">
          <div className="bg-white justify-between p-16 rounded-3xl w-1/2 ">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Invalid package name"
            >
              <Button
                onClick={() => {
                  window.location.href = 'https://getbastion.com';
                }}
                type="primary"
              >
                Back to Bastion
              </Button>
            </Empty>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 md:flex-row min-h-screen flex-col ">
      <div
        css={css`
          background-color: ${colors.primary[500]};
        `}
        className="flex flex-1 flex-col justify-around "
      >
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 md:justify-end justify-center ">
            <div className="lg:w-full md:pr-24 pr-0 pt-24 md:ml-12 max-w-lg">
              <OrderSummary
                isLoading={loadingProductDetails || isRefetching}
                product={productData}
              />
              <SecurityScanOutlined
                className="pl-4 pb-12 md:pb-0"
                style={{ fontSize: 24, color: 'white' }}
              />
              <p className="pl-2 text-white inline ">SECURE SSL CHECKOUT</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 pt-24">
        <div className="flex flex-1 md:justify-start justify-center">
          <div className="flex flex-1 pl-4 pr-4 md-p-0 md:pl-24 md:w-full md:mr-12 max-w-lg  pb-36 md:pb-0">
            {renderSteps()}
          </div>
        </div>
      </div>
    </div>
  );
};
