import React, { useCallback, useEffect, useState } from 'react';
import { Button, Image, Popover } from 'antd';
import { css } from '@emotion/react';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { uniq } from 'lodash';
import { AILMENTS } from '../../../services/package.static';

type Props = {
  onSelect: (value: string[]) => void;
};

export const AilmentCards = ({ onSelect }: Props) => {
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    onSelect(selected);
  }, [selected, onSelect]);

  const renderProductCard = useCallback(
    ailment => {
      const isSelected = selected.includes(ailment.value);
      return (
        <div
          className="flex flex-1 w-full"
          onClick={() => {
            setSelected(pv => {
              //  Remove if already selected and add if not available
              if (pv.includes(ailment.value)) {
                return pv.filter(item => item !== ailment.value);
              }
              return pv.concat(ailment.value);
            });
          }}
        >
          <div
            className={`
          bg-white rounded-lg border border-gray-200  dark:bg-gray-800 dark:border-gray-700 flex flex-1 flex-col
          shadow-md
          hover:border-2 hover:border-primary-600 hover:shadow-md
          ${
            isSelected
              ? 'border-primary-600 border-double- shadow-md bg-primary-500'
              : ''
          }
          `}
            css={css`
              width: 100%;
              /*max-width: 250px;*/
            `}
          >
            <div className="p-3 flex flex-1 flex-col ">
              <div className="flex justify-between flex-row flex-1 items-start">
                <div className="flex flex-row space-x-2">
                  <Image
                    src={isSelected ? ailment.selectedIcon : ailment.icon}
                    preview={false}
                    width={40}
                    height={40}
                  />
                  <div>
                    <p
                      className={`
                      text-l font-bold tracking-tight
                      ${
                        isSelected
                          ? 'text-white'
                          : 'text-gray-900 dark:text-white'
                      }
                      `}
                    >
                      {ailment.name}
                    </p>
                    <p
                      css={css`
                        color: ${isSelected ? 'white' : '#aaa7a7'};
                        font-family: 'Roboto';
                        font-size: 11px;
                        margin-bottom: 10px;
                      `}
                    >
                      {ailment.subName}
                    </p>
                  </div>
                </div>
                <Popover
                  content={ailment.description}
                  title={ailment.name}
                  trigger="hover"
                  overlayClassName="max-w-xs"
                >
                  <InfoCircleOutlined
                    color={'white'}
                    style={{ color: isSelected ? 'white' : '#aaa7a7' }}
                    onClick={() => {}}
                  />
                </Popover>
              </div>

              {/* <div className="flex justify-between flex-row flex-1 items-end"> */}
              {/*  {isSelected ? ( */}
              {/*    <CheckCircleOutlined style={{ color: '#75db6d' }} /> */}
              {/*  ) : null} */}
              {/* </div> */}
            </div>
          </div>
        </div>
      );
    },
    [setSelected, selected],
  );

  const renderDisclaimer = useCallback(() => {
    const selectedPackages = AILMENTS.filter(item =>
      selected.includes(item.value),
    );
    const relatedPackages = uniq(
      selectedPackages.reduce((acc, item) => {
        return acc.concat(item.relatedPackages);
      }, []),
    );

    /* Find those packages that are in relatedPackages but not in selected packages */
    const relatedPackagesNotSelected = relatedPackages.filter(
      item =>
        !selectedPackages
          .map(selectedPackage => selectedPackage.value)
          .includes(item),
    );
    const complimentaryPackages = AILMENTS.filter(item =>
      relatedPackagesNotSelected.includes(item.value),
    );
    if (complimentaryPackages.length === 0) return null;
    const complimentaryPackagesNames = complimentaryPackages.reduce(
      (acc, item, currentIndex) => {
        /* If it's one to last add "and" */
        if (currentIndex === complimentaryPackages.length - 2) {
          return `${acc}${item.name} and `;
        }
        /* If it's last add nothing */
        if (currentIndex === complimentaryPackages.length - 1) {
          return `${acc}${item.name}`;
        }
        /* If it's not last add comma */
        return `${acc}${item.name}, `;
      },
      '',
    );

    return (
      <div
        css={css`
          font-family: 'Roboto';
        `}
        className="pt-5"
      >
        *Note: To comprehensively assess your health your selected package also
        assess you for {complimentaryPackagesNames}.
      </div>
    );
  }, [selected]);

  return (
    <div>
      <div className="grid gap-2 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {AILMENTS.map(ailmet => renderProductCard(ailmet))}
      </div>
      {renderDisclaimer()}
    </div>
  );
};
