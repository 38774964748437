import React, { useCallback, useState } from 'react';

import { Input, Select } from 'antd';

type OptionType = {
  label: string;
  value: string;
};

type Props = {
  onChange: (value: any) => void;
  text: string;
  onClick: (question: string, value: string) => void;
};

export const FreeTextInput = ({ onChange, text, onClick }: Props) => {
  const [selected, setSelected] = useState(false);
  const handleOnClick = useCallback(() => {
    onClick(text, text);
    setSelected(pv => {
      return !pv;
    });
  }, [setSelected, onClick, text]);

  return (
    <div className="flex flex-row h-9 align-top justify-start">
      <div
        style={{
          border: '1px solid black',
          lineHeight: '32px',
        }}
        className={`
        cursor-pointer
        ${selected ? 'text-white bg-primary-600' : 'text-black'}
        ${selected ? 'border-blue' : 'border-black'}
        px-4
        rounded-md
        text-center
        h-9
        mr-2
      `}
        onClick={handleOnClick}
      >
        {text}
      </div>
      {selected && (
        <div className=" w-40">
          <Input
            style={{
              height: 32,
            }}
            height={28}
            placeholder="Answer...."
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};
