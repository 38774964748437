import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
  useLocation,
  useSearchParams,
  useNavigate,
  Link,
} from 'react-router-dom';
import { Image, Space, Tag, Typography } from 'antd';
import qs from 'query-string';
import { isEmpty } from 'lodash';
import { Coupon } from './Coupon';

const PackageIcon = styled.img`
  //height: 42px;
  background-color: aqua;
  display: flex;
  flex-shrink: 0;
  border-radius: 4px;
  max-height: 100%;
  max-width: 100%;

  margin-bottom: 10px;
`;

const LineDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: gray;
`;

export type CouponType = {
  amountOff: number | null;
  percentOff: number | null;
};
export type Price = {
  unit_amount: number;
  currency: string;
};

type Props = {
  isLoading: boolean;
  product: {
    id: string;
    coupon: CouponType;
    name: string;
    description: string;
    images: string[];
    price: Price[];
  };
};
type ParamsProps = {
  productId: string;
};

function calculateDiscount(coupon: CouponType, price: number) {
  if (coupon?.amountOff) {
    return coupon.amountOff / 100;
  }
  if (coupon?.percentOff) {
    return (coupon?.percentOff * price) / 100;
  }
  return 0;
}

function calculatePrice(price: Price[]) {
  return (price && price[0]?.unit_amount / 100) || 0;
}

export const OrderSummary = ({ product, isLoading }: Props) => {
  const location = useLocation();
  const { coupon: couponCode } = qs.parse(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const setQueryParams = useCallback(
    (event: { [key: string]: string }) => {
      // history.push({ pathname: location.pathname, search: params.toString() });
      setSearchParams(event, { replace: true });
    },
    [setSearchParams],
  );

  const removeQueryParams = useCallback(() => {
    setSearchParams('');
  }, [setSearchParams]);

  const { coupon } = product;
  const [discount, setDiscount] = useState(0);

  const price = useMemo(() => {
    return (product?.price && product?.price[0]?.unit_amount / 100) || 0;
  }, [product]);

  const invalidCoupon = useMemo(() => {
    if (isEmpty(couponCode) || isLoading) return false;
    if (!isEmpty(couponCode) && isEmpty(coupon)) return true;
    return !(!isEmpty(couponCode) && calculateDiscount(coupon, price) > 0);
  }, [coupon, couponCode, price, isLoading]);

  useEffect(() => {
    setDiscount(calculateDiscount(coupon, price));
  }, [coupon, price]);

  const subTotal = useMemo(() => {
    return (price - discount).toFixed(2);
  }, [discount, price]);

  const renderDiscount = useCallback(() => {
    if (discount) {
      return (
        <div className="flex flex-row justify-between pb-3 pt-3">
          <Space direction="horizontal">
            <p className="font-bold text-white">Discount</p>
            <Tag
              className="cursor-pointer"
              onClick={() => {
                removeQueryParams();
              }}
            >
              {' '}
              Remove{' '}
            </Tag>
          </Space>
          <p className="font-bold text-white">{`- $${discount}`}</p>
        </div>
      );
    }

    return (
      <Coupon
        disabled={false}
        stripeClientIsLoading={false}
        invalidCoupon={invalidCoupon}
        applyCoupon={async value => {
          setQueryParams({
            coupon: value,
          });
        }}
      />
    );
  }, [discount, removeQueryParams, setQueryParams, invalidCoupon]);

  const renderPrice = useCallback(() => {
    return (
      <p className="text-white font-bold text-3xl mb-2">
        US$ {price.toFixed(2)}
      </p>
    );
  }, [price]);

  const renderBastionLogo = useCallback(() => {
    return (
      <div className="pb-10">
        <div
          onClick={() => {
            window.location.href = 'https://getbastion.com';
          }}
          className="flex flex-row align-middle cursor-pointer "
        >
          <Image
            preview={false}
            width={28}
            className="rounded-full"
            src="https://images.typeform.com/images/EUWruLvdHE8P/choice/thumbnail"
          />
          <Typography.Text
            style={{ color: 'white', alignSelf: 'center', paddingLeft: 8 }}
          >
            Bastion Health
          </Typography.Text>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="w-full">
      <div className=" p-4 flex flex-1 md:flex-col flex-row justify-between leading-normal">
        <div className="mb-8">
          <div className="text-center md:text-left">
            {renderBastionLogo()}
            <p className="text-gray-400 font-bold text-sm mb-1">
              Pay Bastion Health
            </p>
            {renderPrice()}
          </div>
          <div className="flex flex-1 flex-col md:flex-row pt-3 justify-center ">
            <div className="w-auto h-36 md:h-12 md:w-1/5 justify-center self-center md:self-auto md:pb-0 pb-12 flex flex-1 max-w-sm">
              {product?.images.map(url => (
                <PackageIcon
                  className="h-full self-center md:self-auto"
                  src={url}
                />
              ))}
            </div>

            <div className="md:w-4/5 pl-3 flex-col ">
              <div className="w-full flex flex-row">
                <p className="text-white">{product.name}</p>
                <div className="flex flex-1 justify-end">
                  <p className="text-white ">{`$${price.toFixed(2)}`}</p>
                </div>
              </div>
              <p className="text-gray-400 pb-10">{product.description}</p>
              <div className="flex flex-row justify-between pb-3">
                <p className="font-bold text-white">Subtotal</p>
                <p className="font-bold text-white">{`US$${price.toFixed(
                  2,
                )}`}</p>
              </div>
              <LineDivider />
              {renderDiscount()}
              <LineDivider />
              <div className="flex flex-row justify-between pt-3">
                <p className="font-bold text-white">Total due</p>
                <p className="font-bold text-white">{`US$${subTotal}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
