export type StateNameType = {
  value: string;
  abbr: string;
};
export const StateName = {
  ALABAMA: {
    value: 'Alabama',
    abbr: 'AL',
  },
  ALASKA: {
    value: 'Alaska',
    abbr: 'AK',
  },
  AMERICAN_SAMOA: {
    value: 'American Samoa',
    abbr: 'AS',
  },
  ARIZONA: {
    value: 'Arizona',
    abbr: 'AZ',
  },
  ARKANSAS: {
    value: 'Arkansas',
    abbr: 'AR',
  },
  CALIFORNIA: {
    value: 'California',
    abbr: 'CA',
  },
  COLORADO: {
    value: 'Colorado',
    abbr: 'CO',
  },
  CONNECTICUT: {
    value: 'Connecticut',
    abbr: 'CT',
  },
  DELAWARE: {
    value: 'Delaware',
    abbr: 'DE',
  },
  DISTRICT_OF_COLUMBIA: {
    value: 'District Of Columbia',
    abbr: 'DC',
  },
  FEDERATED_STATES_OF_MICRONESIA: {
    value: 'Federated States Of Micronesia',
    abbr: 'FM',
  },
  FLORIDA: {
    value: 'Florida',
    abbr: 'FL',
  },
  GEORGIA: {
    value: 'Georgia',
    abbr: 'GA',
  },
  GUAM: {
    value: 'Guam',
    abbr: 'GU',
  },
  HAWAII: {
    value: 'Hawaii',
    abbr: 'HI',
  },
  IDAHO: {
    value: 'Idaho',
    abbr: 'ID',
  },
  ILLINOIS: {
    value: 'Illinois',
    abbr: 'IL',
  },
  INDIANA: {
    value: 'Indiana',
    abbr: 'IN',
  },
  IOWA: {
    value: 'Iowa',
    abbr: 'IA',
  },
  KANSAS: {
    value: 'Kansas',
    abbr: 'KS',
  },
  KENTUCKY: {
    value: 'Kentucky',
    abbr: 'KY',
  },
  LOUISIANA: {
    value: 'Louisiana',
    abbr: 'LA',
  },
  MAINE: {
    value: 'Maine',
    abbr: 'ME',
  },
  MARSHALL_ISLANDS: {
    value: 'Marshall Islands',
    abbr: 'MH',
  },
  MARYLAND: {
    value: 'Maryland',
    abbr: 'MD',
  },
  MASSACHUSETTS: {
    value: 'Massachusetts',
    abbr: 'MA',
  },
  MICHIGAN: {
    value: 'Michigan',
    abbr: 'MI',
  },
  MINNESOTA: {
    value: 'Minnesota',
    abbr: 'MN',
  },
  MISSISSIPPI: {
    value: 'Mississippi',
    abbr: 'MS',
  },
  MISSOURI: {
    value: 'Missouri',
    abbr: 'MO',
  },
  MONTANA: {
    value: 'Montana',
    abbr: 'MT',
  },
  NEBRASKA: {
    value: 'Nebraska',
    abbr: 'NE',
  },
  NEVADA: {
    value: 'Nevada',
    abbr: 'NV',
  },
  NEW_HAMPSHIRE: {
    value: 'New Hampshire',
    abbr: 'NH',
  },
  NEW_JERSEY: {
    value: 'New Jersey',
    abbr: 'NJ',
  },
  NEW_MEXICO: {
    value: 'New Mexico',
    abbr: 'NM',
  },
  NEW_YORK: {
    value: 'New York',
    abbr: 'NY',
  },
  NORTH_CAROLINA: {
    value: 'North Carolina',
    abbr: 'NC',
  },
  NORTH_DAKOTA: {
    value: 'North Dakota',
    abbr: 'ND',
  },
  NORTHERN_MARIANA_ISLANDS: {
    value: 'Northern Mariana Islands',
    abbr: 'MP',
  },
  OHIO: {
    value: 'Ohio',
    abbr: 'OH',
  },
  OKLAHOMA: {
    value: 'Oklahoma',
    abbr: 'OK',
  },
  OREGON: {
    value: 'Oregon',
    abbr: 'OR',
  },
  PALAU: {
    value: 'Palau',
    abbr: 'PW',
  },
  PENNSYLVANIA: {
    value: 'Pennsylvania',
    abbr: 'PA',
  },
  PUERTO_RICO: {
    value: 'Puerto Rico',
    abbr: 'PR',
  },
  RHODE_ISLAND: {
    value: 'Rhode Island',
    abbr: 'RI',
  },
  SOUTH_CAROLINA: {
    value: 'South Carolina',
    abbr: 'SC',
  },
  SOUTH_DAKOTA: {
    value: 'South Dakota',
    abbr: 'SD',
  },
  TENNESSEE: {
    value: 'Tennessee',
    abbr: 'TN',
  },
  TEXAS: {
    value: 'Texas',
    abbr: 'TX',
  },
  UTAH: {
    value: 'Utah',
    abbr: 'UT',
  },
  VERMONT: {
    value: 'Vermont',
    abbr: 'VT',
  },
  VIRGIN_ISLANDS: {
    value: 'Virgin Islands',
    abbr: 'VI',
  },
  VIRGINIA: {
    value: 'Virginia',
    abbr: 'VA',
  },
  WASHINGTON: {
    value: 'Washington',
    abbr: 'WA',
  },
  WEST_VIRGINIA: {
    value: 'West Virginia',
    abbr: 'WV',
  },
  WISCONSIN: {
    value: 'Wisconsin',
    abbr: 'WI',
  },
  WYOMING: {
    value: 'Wyoming',
    abbr: 'WY',
  },
};
